import React from "react";
import Badge from "../../ui/Badge/Badge";

import './menu-item.css'

function MenuItem(props) {

  return (
    <div className="menu-item_container">
        <div className="menu-item-image" style={{backgroundImage: 'url('+ props.src + ')'}} />
        <div className="menu-item_title">
            <h1>{props.name}</h1>
            {props.isVegan && <Badge label='VEGAN' color='green' />}
            {props.isNew && <Badge label='NOUVEAU' color='brown' />}
        </div>
        <div className="menu-item_desc">
            <h2>{props.description}</h2>
            <h3>{props.nutri}</h3>
        </div>
    </div>
    );
}

// <img src={props.src} alt='menu item - roll, salad, cake, soup, dessert'/>

export default MenuItem;
import React from "react";

import './location.css'

import Metro from './metro.svg'

import TextButton from "../ui/TextButton/TextButton";
import Navbar from "../ui/Navbar/Navbar";

function LocationPage() {
  return (
    <>
        <Navbar />
        <div className="location-page_wrapper">
            
            <div className="location-page_section">
                <div className="location-page_content">
                    <h2>TROUVEZ-NOUS</h2>
                    <h1>Bonjour Paris!</h1>
                    <p>Notre premier point de vente ouvre bientôt ses portes dans la ville lumière, Paris. Restez à l'écoute pour découvrir notre adresse. Nous avons hâte de vous rencontrer !</p>
                    <TextButton href='https://www.instagram.com/forkless.fr/' label='Instagram' color='green' />
                    <TextButton href='' label='TikTok' color='green' />
                    <TextButton href='https://twitter.com/forkless_fr' label='Twitter' color='green' />
                    <TextButton href='https://music.apple.com/fr/playlist/forkless-radio/pl.u-11zB9vbFjy5pxy?l=en' label='Apple Music' color='green' />
                    <TextButton href='https://open.spotify.com/playlist/06zwebzKbhoWkrUHgpmAG4?si=3b29aa83fc4c4f5e' label='Spotify' color='green' />
                </div>
            </div>
        </div>
        <div className="location-page_metro">
            <img src={Metro} alt='Cutting board with wrap ingredients' />
        </div>
    </>
    );
}

export default LocationPage;
import React from "react";

import './about-header.css'
import Button from "../../ui/Button/Button";

function AboutHeader() {

  return (
    <div className="about-header_wrapper">
        <div className="about-header-empty"></div>
        <div className="about-header-content">
            <h2>A PROPOS</h2>
            <h1>C'est quoi Forkless?</h1>
        </div>
    </div>
    );
}

export default AboutHeader;
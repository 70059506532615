import React from "react";

import './badge.css'


function Badge(props) {

    let backgroundColor = 'black';
    let textColor = 'white';

    if (props.color === 'red') {
        backgroundColor = '#EAC4B9';
        textColor = '#C25637';
    }

    if (props.color === 'blue') {
        textColor = '#CBD2E1';
         backgroundColor = '#2860A1';
    }

    if (props.color === 'green') {
        backgroundColor = '#2F482A';
        textColor = '#C3CEB5';
    }

    if (props.color === 'brown') {
        textColor = '#E2D5B7';
        backgroundColor = '#AD7A44';
    }

  return (
        <div className="badge_container" style={{backgroundColor: backgroundColor, color: textColor}}>
            <p>{props.label}</p>
        </div>
    );
}

export default Badge;
import React from "react";

import './text-button.css'


function TextButton(props) {
    let textColor = 'white';

    if (props.color === 'red') {
        textColor = '#C25637';
    }

    if (props.color === 'blue') {
        textColor = '#2860A1';
    }

    if (props.color === 'green') {
        textColor = '#2F482A';
    }

    if (props.color === 'brown') {
        textColor = '#AD7A44';
    }

    if (props.color === 'black') {
        textColor = 'black';
    }

    if (props.isActive) {
        return (
            <a style={{ color: textColor, textDecoration: 'underline'}} href={props.href} className='text-button_container'>
                <p>{props.label}</p>
            </a>
        )
    }
    return (
        <a style={{ color: textColor}} href={props.href} className='text-button_container'>
            <p>{props.label}</p>
        </a>
    )
}

export default TextButton;
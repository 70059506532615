import React from "react";
import MenuItem from "../MenuItem/MenuItem";

import './menu-section.css'

function MenuSection(props) {

  return (
    <div className="menu-section_wrapper">
        <div className="menu-section_header">
            <h1>{props.title}</h1>
        </div>
        <div className="menu-section_grid">
            {props.items.map(function(item) {
                return <MenuItem isVegan={item.isVegan} isNew={item.isNew} name={item.name} description={item.description} nutri={item.nutri} src={item.src} />
            })}
        </div>
    </div>
    );
}

export default MenuSection;
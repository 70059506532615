import React from "react";

import './header.css'
import Button from "../../ui/Button/Button";

function Header() {

  return (
    <div className="header_wrapper">
        <div className="header-empty"></div>
        <div className="header-content">
            <h1>Prendre le temps de manger</h1>
            <h1>sans vous retarder</h1>
            <Button label="EN SAVOIR PLUS" color="white" href="/about" marginTop="16px" />
        </div>
    </div>
    );
}

export default Header;
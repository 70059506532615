import React from "react";

import './button.css'


function Button(props) {

    let backgroundColor = 'black';
    let textColor = 'white';

    if (props.color === 'red') {
        backgroundColor = '#EAC4B9';
        textColor = '#C25637';
    }

    if (props.color === 'blue') {
        textColor = '#CBD2E1';
         backgroundColor = '#2860A1';
    }

    if (props.color === 'green') {
        backgroundColor = '#2F482A';
        textColor = '#C3CEB5';
    }

    if (props.color === 'brown') {
        textColor = '#E2D5B7';
        backgroundColor = '#AD7A44';
    }

    if (props.color === 'white') {
        textColor = 'black';
        backgroundColor = 'white';
    }
    
    return (
        <a href={props.href} className="pill-button_container" style={{backgroundColor: backgroundColor, color: textColor, marginBottom: props.marginBottom, marginTop: props.marginTop}}>    
        <div className="pill-button_container" style={{backgroundColor: backgroundColor, color: textColor}}>
            <p>{props.label}</p>
            </div>
        </a>
    )
}

export default Button;
import React from "react";
import Header from "./Header/Header";
import About from "./About/About";

import MenuHighlights from "./MenuHighlights/MenuHighlights";
import Location from "../ui/Location/Location";
import Navbar from "../ui/Navbar/Navbar";

function Homepage() {
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <MenuHighlights />
      <Location />
    </>
    );
}

export default Homepage;
import React from "react";

import './menu-header.css'
import Button from "../../ui/Button/Button";

function AboutHeader() {

  return (
    <div className="menu-header_wrapper">
        <div className="menu-header-content">
            <h2>LA CARTE</h2>
            <h1>Trouvez votre nouveau coup de coeur.</h1>
        </div>
        <div className="menu-header-image-wrapper">
            <div className="menu-header-image" />
        </div>
    </div>
    );
}

export default AboutHeader;
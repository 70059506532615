import React from "react";
import Marquee from "react-fast-marquee";

import {ReactComponent as Instagram} from './insta.svg'
import {ReactComponent as TikTok} from './tiktok.svg'
import {ReactComponent as AppleMusic} from './apple.svg'
import {ReactComponent as Spotify} from './spotify.svg'


import './social-ticker.css'

function SocialTicker() {

  return (
    <div className="ticker_wrapper">
        <Marquee gradient={false} speed={50}>
            <Instagram />
            <h1>Suivez notre Insta <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/forkless.fr/'>@forkless.fr</a> |</h1>
            <TikTok />
            <h1>Rejoignez-nous sur TikTok <a target="_blank" rel="noopener noreferrer" href=' '>@forkless.fr</a> |</h1>
            <AppleMusic style={{marginRight: "0.5em"}}/>
            <Spotify />
            <h1>Écoutez Forkless Radio sur <a target="_blank" rel="noopener noreferrer" href='https://music.apple.com/fr/playlist/forkless-radio/pl.u-11zB9vbFjy5pxy?l=en'>Apple Music</a> et <a target="_blank" rel="noopener noreferrer" href='https://open.spotify.com/playlist/06zwebzKbhoWkrUHgpmAG4?si=3b29aa83fc4c4f5e'>Spotify</a> |</h1>
            
        </Marquee>
    </div>
    );
}

export default SocialTicker;
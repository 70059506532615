import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from './components/menu/Menu';
import SocialTicker from './components/ui/SocialTicker/SocialTicker';
import Footer from './components/ui/Footer/Footer';
import About from './components/about/About';
import Events from './components/events/Events';
import LocationPage from './components/location/LocationPage';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<App />} />
          <Route path='menu' element={<>
            <Menu />
            <SocialTicker />
            <Footer />
          </>} />
          <Route path='about' element={<>
            <About />
            <SocialTicker />
            <Footer />
          </>} />
          <Route path='events' element={<>
            <Events />
            <SocialTicker />
            <Footer />
          </>} />
          <Route path='location' element={<>
            <LocationPage />
            <SocialTicker />
            <Footer />
          </>} />
          <Route path='*' element={<App />} />
      </Routes>
      
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

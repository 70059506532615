import React from "react";
import Location from "../ui/Location/Location";

import './about.css'


import About1 from './about1.JPG'
import About2 from './about2.JPG'
import About3 from './about3.png'
import About4 from './about4.png'
import About5 from './about5.png'
import People from './people.png'
import Navbar from "../ui/Navbar/Navbar";
import AboutHeader from "./AboutHeader/AboutHeader";

function Menu() {
  return (
    <>
    <Navbar isActive='about'/>
    <AboutHeader />
    <div className="about-section_wrapper">
        <div className="about-section_grid">
            <div className="about-section_content">
                <h2>NOS PRODUITS</h2>
                <h1>Des produits frais, savoureux et à déguster sur le pouce.</h1>
                <p className="about-section_content_text">
                Nous préparons nos salades et autres recettes tous les jours dans notre cuisine à partir d'ingrédients frais sans rajouter d'additif ni conservateur. Nos recettes sont ensuite livrées chaque matin à notre point de vente afin de vous proposer une offre de qualité.
                </p>
            </div>
            <div className="about-section_grid_image" style={{backgroundImage: `url(${About1})`}}/>
        </div>
    </div>
    <div className="about-section_wrapper">
        <div className="about-section_grid">
            <div className="about-section_grid_image-left" style={{backgroundImage: `url(${About2})`}}/>
            <div className="about-section_content" style={{marginLeft: 'var(--spacing-lg)', marginRight: 'auto'}}>
            <h2>DEVELOPPEMENT DURABLE</h2>
                <h1>Nos choix alimentaires ont un impact direct sur l'environnement.</h1>
                <p className="about-section_content_text">Chez Forkless, proposer une alimentation plus respectueuse de l'environnement est au cœur de notre mission. Nous nous efforçons de réduire au maximum notre impact sur l'environnement en proposant des produits peu consommateur d'ustensiles à usage unique. Pour ce faire, nous offrons la possibilité à notre clientèle d'acheter notre boite repas réutilisable où de venir directement avec la sienne. Par ailleurs, nos emballages sont réalisés à partir de matières naturelles (carton et papier) et sont 100% recyclable.</p>
            </div>
        </div>
    </div>
    <div className="about-page_people">
            <div className="about-page_people-image">
                <img src={People} alt='cute boy on a picnic' />
            </div>
            <p>Un grand merci à tous ceux qui ont participé à la création de cette aventure de près ou de loin. De la conception du site internet à la critique culinaire, sans eux, Forkless n'aurait pu exister. Merci à Sarah, Stefan, Brigitte, Pierre, Leila et Victoria.</p>
        </div>
    <div className="about-section_wrapper">
        <div className="about-section_grid">
            <div className="about-section_grid_image-left" style={{backgroundImage: `url(${About4})`, height:'50vh', borderRight: 'none'}}/>
            <div className="about-section_grid_image" style={{backgroundImage: `url(${About5})`}}/>
        </div>
    </div>
    <Location />
    </>
    );
}

export default Menu;
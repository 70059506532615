import React from "react";
import Location from "../ui/Location/Location";

import './events.css'


import Events1 from './events1.png'
import Button from "../ui/Button/Button";
import Navbar from "../ui/Navbar/Navbar";

function Events() {
  return (
    <>
        <Navbar isActive='events'/>
        <div className="events-page_wrapper">
            
            <div className="events-page_section">
                <div>
                    <img src={Events1} alt='forkless rolls at a picnic' />
                </div>
                <div className="events-page_content">
                    <h2>CATERING</h2>
                    <h1>Forkless chez vous ou au bureau ?</h1>
                    <p>Pris par le temps mais envie de déguster et partager un repas nourrissant et sain avec votre entourage ?</p>
                    <p>Forkless livre ses rolls et autres desserts préparés le jour-même !</p>
                    <p>Bon appétit !</p>
                </div>
            </div>
            <div className="events-page_buttons">
                <h1>Contactez-nous!</h1>
                <Button label="EMAIL | info@forkless.fr" href='mailto:info@forkless.fr' />
                <Button label="TEL | 06.43.73.35.12" href='tel:+33643733512' />
            </div>
        </div>
        <Location />
    </>
    );
}

export default Events;
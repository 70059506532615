import React from "react";
import Location from "../ui/Location/Location";

import './menu.css'
import MenuHeader from "./MenuHeader/MenuHeader";
import MenuSection from "./MenuSection/MenuSection";

import Roll1 from './roll1.png'
import Roll2 from './roll2.png'
import Roll3 from './roll3.png'
import Roll4 from './roll4.png'
import Roll5 from './roll5.png'
import Roll6 from './roll6.png'
import Roll7 from './roll7.png'

import Cookie from './cookie.png'
import Dessert2 from './dessert2.png'
import Dessert3 from './dessert3.png'
import Drink1 from './drink1.png'
import Drink2 from './drink2.png'
import Drink3 from './drink3.png'
import Navbar from "../ui/Navbar/Navbar";

const ROLLS = [
    {
        name: 'Wild Sesame Roll',
        src: Roll1,
        description: 'Feuille de riz, jeunes pousses d’épinards, oignon rouge, concombre, carotte, choux rouge + blanc, graines de sésame, vermicelle de haricot, sauce sésame',
        isNew: true,
        isVegan: true
    },
    {
        name: 'Caesar Roll',
        description: 'Feuille de riz, oeufs, carotte, parmesan, poulet, sauce césar (ail, anchois, huile d’olive, vinaigre de Xerès, crème liquide)',
        src: Roll2,
        isNew: true
    },
    {
        name: 'Nordic Roll',
        description: 'Feuille de riz, saumon, jeunes pousses d\'épinards, concombre, carotte, aneth, sauce tzatziki (soja, huile d’olive, menthe, sel, ail)',
        src: Roll3,
        isNew: true,
    },
    {
        name: 'Sweet Crunch Roll',
        description: 'Feuille de riz, mâche, patate douce, tofu, vermicelle de haricot, carotte, sauce cacahuètes',
        src: Roll4,
        isNew: true,
        isVegan: true
    },
    {
        name: 'Italian Roll',
        description: 'Feuille de riz, roquette, tomate confite, jambon de parme, choux rouge, poivron, sauce au parmesan',
        src: Roll5,
        isNew: true
    },
    {
        name: 'Veggie Roll',
        description: 'Feuille de riz, patate douce, pomme de terre, carotte, broccoli, oignon rouge, tomate cerise, sauce coriandre',
        src: Roll6,
        isNew: true,
        isVegan: true
    },
    {
        name: 'Avocado Toast',
        description: 'Avocat, Feta, radis, tomate cerise, graine de courge',
        src: Roll7,
        isNew: true,
    }
]

const DESSERTS = [
    {
        name: 'Banana + Chocolat + Noix Cookie',
        description: 'Farine, banane, sucre brun, lait de coco, huile de coco, noix, chocolat, extrait de vanille, sel',
        src: Cookie,
        isVegan: true
    },
    {
        name: 'Chia Pudding',
        description: 'Graines de chia, lait d\'amande, sirop d\'agave',
        src: Dessert2,
        isVegan: true
    },
    {
        name: 'Matcha Pudding',
        description: 'Thé matcha, graines de chia, lait d\'amande, sirop d\'agave',
        src: Dessert3,
        isVegan: true
    },
    {
        name: 'Lemonaid',
        description: 'Fruit de la passion, Gingembre, Orange Sanguine, Citron Vert 33cl',
        src: Drink1,
    },
    {
        name: 'Charitea',
        description: 'Green, Red, Black, Sparkling 33cl',
        src: Drink2,
    },
    {
        name: 'L’eau Neuve',
        description: '50cl',
        src: Drink3,
    }
]

function Menu() {
  return (
    <>
        <Navbar isActive='menu' />
        <MenuHeader />
        <MenuSection title='Nos recettes' items={ROLLS} />
        <MenuSection title='Desserts & Boissons' items={DESSERTS} />
        <Location />
    </>
    );
}

export default Menu;
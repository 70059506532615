import logo from './logo.svg';
import './App.css';
import './constants.css';

import Homepage from './components/home/Homepage';
import Footer from './components/ui/Footer/Footer';
import SocialTicker from './components/ui/SocialTicker/SocialTicker';


function App() {
  return (
    <>
      <Homepage />
      <SocialTicker />
      <Footer />
    </>
  );
}

export default App;

import React from "react";

import './navbar.css'

import { Link } from "react-router-dom";

import {ReactComponent as ForklessLogo} from './logo.svg'
import {ReactComponent as HamburgerIcon} from './hamburger.svg'

import Button from "../Button/Button";
import TextButton from "../TextButton/TextButton";

function Navbar(props) {

    const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
        <div className="navbar_wrapper">
            <div className="navbar_hamburger">
                <HamburgerIcon onClick={() => {setIsOpen(!isOpen)}}/>
            </div>
            <div className="navbar_link-container">
                <TextButton label={'LA CARTE'} href='/menu' color='black' isActive={props.isActive === 'menu'} />
                <TextButton label={'A PROPOS'} href='/about' color = 'black' isActive={props.isActive === 'about'} />
            </div>
            <Link to="/"><ForklessLogo /></Link>
            <div className="navbar_link-container" style={{justifyContent: 'flex-end'}}>
                <TextButton label={'CATERING'} href='/events' color = 'black' isActive={props.isActive === 'events'} />
                <Button label={'TROUVEZ NOUS'} href='/location' />
            </div>
        </div>
        {isOpen && 
        <div className="navbar_mobile-menu">
            <TextButton label={'LA CARTE'} href='/menu' color='black' isActive={props.isActive === 'menu'}/>
            <TextButton label={'A PROPOS'} href='/about' color = 'black' isActive={props.isActive === 'about'} />
            <TextButton label={'CATERING'} href='/events' color = 'black' isActive={props.isActive === 'events'} />
            <Button label={'TROUVEZ-NOUS'} href='/location' marginBottom="24px"/>
        </div>}
    </>
    );
}

export default Navbar;
import React from "react";
import Badge from "../../../ui/Badge/Badge";

import './item.css'

function Item(props) {

  return (
    <div className="item_container">
        <img src={props.src} alt='menu item - roll, salad, cake, soup, dessert'/>
        <div className="item_title">
            {props.isVegan && <Badge label='VEGAN' color='green' />}
            {props.isNew && <Badge label='NOUVEAU' color='brown' />}
            <h1>{props.name}</h1>
        </div>
    </div>
    );
}

export default Item;
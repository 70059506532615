import React from "react";

import './about.css'
import Button from "../../ui/Button/Button";

import {ReactComponent as Planet} from './planet.svg'
import {ReactComponent as Heart} from './heart.svg'

function About() {

  return (
    <div className="about_wrapper">
        <div className="about_grid">
            <div className="about_content">
                <h1>C'est quoi Forkless?</h1>
                <p className="about_content_text">Un havre de paix qui saura vous apporter réconfort et sérénité tout en profitant de recettes savoureuses, équilibrées et gourmandes. </p>
                <p className="about_content_text">Le bilan calorique de nos rolls est disponible en point de vente et sur notre site internet. </p>
                <p className="about_content_text">Forkless se veut le plus inclusif possible et propose des recettes végétarienne, végan mais également des rolls à base de poulet ou encore de saumon.</p>
                <div className="about_tiles_container">
                    <div className="about_tile">
                        <Planet />
                        <p>Nos choix ont un impact sur l'environnement. Forkless vous donne les outils pour agir.</p>
                    </div>
                    <div className="about_tile">
                        <Heart />
                        <p>Des produits frais, savoureux et à déguster sur le pouce.</p>
                    </div>
                </div>
                <Button href='/about' color='green' label='NOTRE MISSION' marginTop="16px"/>
            </div>
            <div className="about_grid_image" />
        </div>
    </div>
    );
}

export default About;
import React from "react";

import Metro from './metro.svg';

import './location.css'

function Location() {

  return (
    <div className="location_wrapper">
        <h1>Bonjour Paris!</h1>
        <div className="location_metro">
          <img src={Metro} alt='Cutting board with wrap ingredients' />
        </div>
        <p>Notre premier point de vente ouvre bientôt ses portes dans la ville lumière. Restez à l'écoute pour découvrir notre adresse. Nous avons hâte de vous rencontrer !</p>
    </div>
    );
}

export default Location;
import React from "react";

import './menu-highlights.css'
import Button from "../../ui/Button/Button";
import Item from "./Item/Item";

import Roll1 from './roll1.png'
import Roll2 from './roll2.png'
import Roll3 from './roll3.png'

const ITEMS = [
    {
        name: 'Wild Sesame Roll',
        src: Roll1,
        isNew: true,
        isVegan: true
    },
    {
        name: 'Caesar Roll',
        src: Roll2,
        isNew: true
    },
    {
        name: 'Nordic Roll',
        src: Roll3,
        isNew: true,
    }

]

function MenuHighlights() {

  return (
    <div className="menu-highlights_wrapper">
        <div className="menu-highlights_header">
            <h1>Nos plaisirs (non) coupables préférés</h1>
            <div className="menu-highlights_header_content">
                <p >Découvrez notre menu qui inclut des options végétarienne, végan ou encore sans gluten à emporter partout avec vous.</p>
                <div style={{marginTop: '16px'}}>
                    <Button color='brown' label='VOIR LE MENU' href='/menu' marginTop="16px" />
                </div>
            </div>
            
        </div>
        <div className="menu-highlights_grid">
            {ITEMS.map(function(item) {
                return <Item key={item.name} isVegan={item.isVegan} isNew={item.isNew} name={item.name} src={item.src} />
            })}
        </div>
    </div>
    );
}

export default MenuHighlights;
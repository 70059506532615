import React from "react";
import TextButton from "../TextButton/TextButton";

import {ReactComponent as ForklessLogo} from './logo.svg'

import './footer.css'

function Footer() {

  return (
    <div className="footer_wrapper">
        <div className="footer_section">
            <ForklessLogo />
        </div>
        <div className="footer_section">
            <h3>Adresse bientôt révélée</h3>
            <TextButton href='mailto:info@forkless.fr' label="info@forkless.fr" color="green" />
            <h3>06.43.73.35.12</h3>
        </div>
        <div className="footer_section">
            <TextButton href='/about' label='A PROPOS' color='green' />
            <TextButton href='/menu' label='MENU' color='green' />
            <TextButton href='/events' label='EVENEMENTS & CATERING' color='green' />
            <TextButton href='/location' label='POINT DE VENTE' color='green' />
        </div>
        <div className="footer_section">
            <h3>SUIVEZ FORKLESS</h3>
            <TextButton href='https://www.instagram.com/forkless.fr/' label='Instagram' color='green' />
            <TextButton href='' label='TikTok' color='green' />
            <TextButton href='https://twitter.com/forkless_fr' label='Twitter' color='green' />
            <TextButton href='https://music.apple.com/fr/playlist/forkless-radio/pl.u-11zB9vbFjy5pxy?l=en' label='Apple Music' color='green' />
            <TextButton href='https://open.spotify.com/playlist/06zwebzKbhoWkrUHgpmAG4?si=3b29aa83fc4c4f5e' label='Spotify' color='green' />
        </div>
        <div className="footer_section">
            <h3>2022 ® Forkless</h3>
            <h4>made with love by Stefan.</h4>
        </div>
    </div>
    );
}

export default Footer;